import React from 'react';
import { graphql } from 'gatsby';
import ArticleTemplate, { ArticleType } from '../components/ArticleTemplate';
import Cookie from '../components/shared/Cookie';

const Person = ({ data }) => {
  const { wpPerson, wp } = data;
  return (   
    <ArticleTemplate
      article={wpPerson}
      articleType={ArticleType.person}
      siteMeta={wp.generalSettings}
      seo={wpPerson.seo}
    />
  );
};

export const pageQuery = graphql`
  query PersonById($id: String!) {
    wpPerson(id: { eq: $id }) {
      ...PersonFragment
    }
    ...WordpressSiteMetadataFragment
  }
`;

export default Person;
